<template>
  <div class="netDiskS">
    <!-- 遮罩 -->
    <!-- <div class="mask" v-if="isLogin"></div> -->

    <div class="box">
      <div class="left leftFlex">
        <div class="disk-container">
          <!-- 侧边栏 -->
          <disk-sidebar />
          <div class="disk-content">
            <OfficeNav>
              <div class="main">
                <div
                  class="upload-btn"
                  @click="uploadFiles"
                  v-show="uploadFilesNav"
                >
                  <i class="upload-btn-icon"></i> 上传
                </div>
                <div class="create-dir" @click="addFile" v-show="addFiles">
                  <i class="create-dir-icon"></i> 新建文件夹
                </div>

                <div class="storage-detail">
                  <div class="storage-proportion" ref="messageInfoTableRef">
                    <img :src="require('assets/images/rl.png')" alt="" />
                    {{ nub }}
                  </div>
                </div>
              </div>
              <div class="analysis-progress" v-if="progressDelay">
                解析中
                <el-progress :percentage="progress"></el-progress>
              </div>
              <div
                class="create-dir1 create-dir"
                @click="removeFile"
                v-if="addFiles2 && this.$store.state.sidebarActive == 10"
              >
                清空所有文件
              </div>
            </OfficeNav>
            <disk-search @handleSearch="handleSearch" ref="valueSearch" />

            <div class="dik-nav">
              <!--刷新 -->
              <div class="disk-operate">
                <div class="disk-operate-prev" @click="Backoff"></div>
                <div class="disk-operate-refresh" @click="refresh"></div>
              </div>
              <!-- 路径 -->
              <disk-path :dataItem="MyItem" ref="diskPath" />
              <!-- 搜索 -->
              <!-- 头部 -->
              <disk-header
                ref="uploadFiles"
                @uploadFiles="(val) => (uploadFilesNav = val)"
                @addFile="(val) => (addFiles = val)"
              />
              <!-- <div class="show-type" @click="showType"></div> -->
            </div>
            <div v-if="showDale" class="showDale">
              提示:回收站内容10天后自动删除
            </div>
            <disk-table-row
              :listData="fileList"
              ref="diskTable"
              :total="totals"
            />
          </div>
        </div>
      </div>
      <!-- <div class="right recommendRightList">
        <recommend :showMore="'showMore'" />
      </div> -->
    </div>

    <!--上传附件弹出框 -->
    <el-dialog
      v-dialogDrag
      title="文件上传"
      center
      :visible.sync="uploadVisible"
      @close="handlerClose"
      class="upload"
    >
      <UploadBigFile
        class="uploadSlot"
        v-if="uploadVisible && uploadShow"
        :parentId="parentId"
        :columnType="columnType"
        :fileType="fileType"
        :progress="progress"
        @onFileSuccess="onFileSuccess"
        @onFileErrors="onFileError"
        ref="uploader"
      ></UploadBigFile>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sureUpload" :loading="loading">确定上传</el-button>
        <el-button @click="clearUpload">清空</el-button>
        <el-button
          @click="
            () => {
              uploadVisible = false
              loading = false
            }
          "
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增文件夹 -->
    <el-dialog
      :title="addtitle"
      :visible.sync="addFilesPopup"
      class="addFolder"
      @close="closeAddpopup"
    >
      <el-form ref="form" :model="form" label-width="60px">
        <el-form-item label="名称：">
          <el-input v-model="form.name" @keydown.enter="handleSure"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSure">确 定</el-button>
        <el-button @click="addFilesPopup = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- vip权益 -->
    <el-dialog :visible.sync="dialogVisible" class="interest">
      <div class="content">
        <div class="content-item" v-for="(item, index) in vipList" :key="index">
          <div class="content-item-title">
            <div class="content-item-line"></div>
            {{ item.title }}
          </div>
          <div class="content-desc">
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon drillIcon"></i>
                <div class="desc-item-content-title">{{ item.size }}</div>
                <div class="desc-item-content-story">{{ item.sizeDesc }}</div>
              </div>
            </div>
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon textIcon"></i>
                <div class="desc-item-content-title">{{ item.amount }}</div>
                <div class="desc-item-content-story">{{ item.amountDesc }}</div>
              </div>
            </div>

            <div class="desc-bigBox">
              <div class="desc-bigBox-text">
                <div class="desc-bigBox-title">
                  年卡：<span>{{ item.yearCard }}￥</span>
                </div>
                <div class="desc-bigBox-story">{{ item.yearCardDesc }}</div>
              </div>
              <div class="desc-bigBox-btn" @click="descBigBoxbtn">
                {{ item.classify == 1 ? '开通VIP' : '开通SVIP' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 删除弹框 -->
    <el-dialog
      :visible.sync="showRealNameTips"
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      class="real-name-dialog"
    >
      <div class="content">确定删除选中的文件吗?</div>
      <div class="footer">
        <span class="now" @click="removeFiles">确定</span>
        <span class="next" @click="showRealNameTips = false">取消</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import recommend from "components/recommend/recommend.vue";
import DiskSidebar from 'components/disk/DiskSidebar.vue'
import DiskHeader from 'components/disk/DiskHeader.vue'
import DiskPath from 'components/disk/DiskPath.vue'
import DiskSearch from 'components/disk/DiskSearch.vue'
import DiskTableRow from 'components/disk/DiskTableRow.vue'
import UploadBigFile from 'components/disk/DiskUpload.vue'
import OfficeNav from 'components/office-nav/OfficeNav'
// import disablemenu from "utils/contextmenumixin";
import { memoryFile, parse, diskOcr } from 'api/disk'
import { getToken } from 'utils/auth.js'
import {
  getDiskList,
  sideBarType,
  searchList,
  addFiles,
  changeFilesName,
  fileDownload,
  delFiles,
  findParsing,
  diskByDate,
} from 'api/disk'

export default {
  name: 'netDiskS',
  provide() {
    return {
      disk: this,
    }
  },
  // mixins: [disablemenu],
  data() {
    return {
      // 按钮加载
      loading: false,
      uploadShow: true,
      // 删除弹框
      showRealNameTips: false,
      siderNum: {
        id: 0,
        name: '',
      },
      nubs1: 1,
      nubs: 1,
      // 解析实时数据
      diskDate: [],
      diskDates: [],
      request: require('request'),
      fs: require('fs'),
      checkData: [],
      moveid: '',
      type: 0,
      stry: 0,
      MyItem1: [], //储存超出的路径
      MyItem: [{ id: '0', name: '我的网盘' }], // 路径
      fileList: [], //数据
      showDale: false,
      conste: '',
      // 确定上传时间戳
      newDate: '',
      // 解析
      resDelay: '',
      // 上传文件
      fileLists: [],
      showDalget: true, //页面显示状态
      dialogVisible: false, //vip权益
      addFilesPopup: false, //新增文件夹弹窗
      uploadVisible: false, //上传文件弹框
      addtitle: '新增文件夹', //弹框标题
      columnType: 1, //类型  网盘传1  版权传2  原创存证3 溯源4
      parentId: null, //父级ID
      fileType: 0, //文件类型 0全部 word1  图片2 视频3 音频4
      offsetWidth: 0, //路径宽度
      timerid: null,
      // 分页
      totals: 0,
      form: {
        name: '',
      }, //新建文件夹名称
      // VIP弹框内容
      vipList: [
        {
          title: 'VIP会员尊享权益',
          size: '50G',
          sizeDesc: '存证网盘',
          amount: '1万字/月',
          amountDesc: '字符包',
          yearCard: '99',
          yearCardDesc: '仅￥8.25/月',
          classify: 1, //1是VIP 2是SVIP
        },
        {
          title: 'SVIP会员尊享权益',
          size: '100G',
          sizeDesc: '存证网盘',
          amount: '2万字/月',
          amountDesc: '字符包',
          yearCard: '199',
          yearCardDesc: '仅￥16.58/月',
          classify: 2, //1是VIP 2是SVIP
        },
      ],
      isLogin: false,
      uploadFilesNav: true,
      addFiles: true,
      value: false,
      // 自动智能ocr
      ocrValue: false,
      nub: '',
      //解析进度
      progress: 0,
      progressDelay: false,
      parsed: false,

      addFiles2: false,
      isForEach: false,
    }
  },
  components: {
    DiskSidebar,
    DiskHeader,
    DiskPath,
    DiskSearch,
    DiskTableRow,
    UploadBigFile,
    OfficeNav,
    // recommend,
  },

  created() {
    if (!getToken('auth_token')) {
      this.$message({
        message: '请先登录！',
        duration: 1500,
      })
      this.isLogin = true
      return window.loginFun()
    }

    if (this.$route.query.type) {
      console.log(this.$route.query.type)
      if (this.$route.query.type == 1) {
        this.getsideBarType({
          name: '一键采集',
          pageIndex: 1,
          pageSize: 10,
        })
        this.$nextTick(() => {
          this.$store.commit('ChangeSideBarId', 20)
        })
        this.getMemoryFile()
        return
      }
    }

    let id = this.$store.state.sidebarActive
    this.$nextTick(() => {
      if (id == 66) {
        this.getsideBarType({
          name: '网页采集文档',
          pageIndex: 1,
          pageSize: 10,
        })
        this.$store.commit('ChangeSideBarId', 8)
      } else if (id == 77) {
        this.getsideBarType({
          name: '收藏文档',
          pageIndex: 1,
          pageSize: 10,
        })
        this.$store.commit('ChangeSideBarId', 11)
      } else if (id == 88) {
        this.getsideBarType({
          name: '收藏文档',
          pageIndex: 1,
          pageSize: 10,
        })
        this.$store.commit('ChangeSideBarId', 11)
      } else {
        this.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: '',
          timeSort: 'desc',
        })
      }
    })
    this.getMemoryFile()
  },
  watch: {
    diskDate(val) {
      this.diskDates = val
    },
  },
  methods: {
    // // 获取解析数据
    // async diskByDate(val) {
    //   this.a = 1
    //   let data = await diskByDate(val)
    //   this.isForEach = true
    //   let a = []
    //   data.data.forEach((item) => {
    //     a.push(item.isParse)
    //     if (item.isParse == 1) {
    //       setTimeout(() => {
    //         this.diskByDate(this.newDate)
    //       }, 4000)
    //     } else {
    //       setTimeout(() => {
    //         this.fileList.forEach((item) => {
    //           // 添加是否解析完毕字段
    //           this.diskDates.forEach((i) => {
    //             if (item.id == i.id) {
    //               item.progress = 100
    //               item.analysis = 0
    //             }
    //           })
    //         })
    //         this.isForEach = false
    //         this.getDiskLists({
    //           id: 0,
    //           pageIndex: 1,
    //           pageSize: 10,
    //           sizeSort: '',
    //           timeSort: 'desc',
    //         })
    //       }, 5000)
    //     }
    //   })
    //   this.diskDate = data.data
    // },
    // 清空上传弹框
    clearUpload() {
      this.$refs['uploader'].tableData = []
      this.$refs['uploader'].fileIndex = 0
      this.$bus.$emit('clearFileList')
      this.uploadShow = false
      this.loading = false
      this.$nextTick(() => {
        this.uploadShow = true
      })
    },

    //确定上传
    sureUpload() {
      var date = new Date()
      var YY = date.getFullYear() + '-'
      var MM =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hh =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var mm =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
      var ss =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

      this.newDate = YY + MM + DD + ' ' + hh + mm + ss
      if (this.$refs['uploader'].tableData.length != 0) {
        this.loading = true
        this.$refs['uploader'].tableData.forEach((f, fi) => {
          const uploader = this.$refs['uploader']
          uploader.computeMD5(f)
        })
      } else {
        this.loading = false
      }

      // if (this.sure) {
      //   this.sure = false
      //   this.$refs['uploader'].tableData.forEach((f, fi) => {
      //     const uploader = this.$refs['uploader']
      //     uploader.computeMD5(f)
      //   })
      // }
    },
    // 解析接口
    async findParsing() {
      this.resDelay = await findParsing()
      let _that = this
      if (this.resDelay.code == 200) {
        if (this.resDelay.data) {
          _that.timerid = setTimeout(() => {
            this.findParsing()
          }, 5000)
        } else {
          clearTimeout(_that.timerid)
          this.progress = 100
          this.refresh()
        }
      }
    },
    //查询是否解析中
    async isParsing() {
      const res = await findParsing()
      if (res.code == 200) {
        if (res.data) {
          // this.progressDelay = true
          // this.$refs['uploader'].analysisProgress()
          // this.findParsing()
        } else {
          this.progressDelay = false
          this.progress = 100
        }
      }
      // const res = await new Promise(resolve => {
      //   setTimeout(()=>{
      //     if(!this.parsed){
      //       resolve({
      //         code : 200,
      //         data : true,
      //         message : "解析中"
      //       });
      //     }else{
      //       resolve({
      //         code : 200,
      //         data : false,
      //         message : "解析完成"
      //       });
      //     }
      //   },500);
      // });
      // if(res.code == 200){
      //   if(res.data){
      //     this.progressDelay = true;
      //     this.analysisProgress();
      //   }else{
      //     this.progress = 100;
      //   }
      // }
    },
    //空间不足  上传失败
    onFileError(id) {
      this.clearUpload()
    },
    onFileSuccess(file) {
      // setTimeout(() => {
      this.uploadVisible = false
      this.loading = false
      this.getMemoryFile()
      //   this.diskByDate(this.newDate)
      // }, 1000)
    },

    switchValue(val) {
      let id = {}
      if (val) {
        // this.$refs["diskTable"].showSwite = true;
        id = {
          id: 1,
        }
      } else {
        // this.$refs["diskTable"].showSwite = false;

        id = {
          id: 0,
        }
      }
      parse(id)
    },
    switchOcr(val) {
      console.log(val)
      let id = {}
      if (val) {
        id = {
          id: 1,
        }
      } else {
        id = {
          id: 0,
        }
      }
      diskOcr(id)
    },
    // 获取存储空间
    getMemoryFile() {
      memoryFile().then((res) => {
        if (res.data.isParse == 1) {
          this.value = true
          // this.$refs['diskTable'].showSwite = true
        } else {
          this.value = false
          // this.$refs['diskTable'].showSwite = false
        }
        if (res.data.isOcr == 1) {
          this.ocrValue = true
        } else {
          this.ocrValue = false
        }
        this.nub = res.data.usedSpace + ' / ' + res.data.totalSpace

        // this.$nextTick(() => {
        //   if (this.$refs.messageInfoTableRef.offsetWidth > 80) {
        //     this.dilatation = false;
        //   } else {
        //     this.dilatation = true;
        //   }
        // });
        // this.$refs.progressinner.style.width = res.data.rario + "%";
      })
    },
    // 路径超出隐藏
    beyondHiding() {
      this.$nextTick(() => {
        this.$refs.diskPath.getdiskpath()
        if (this.$refs.diskPath.diskPath2 >= 480) {
          this.MyItem1.push(this.MyItem.shift())
        }
      })
    },
    // 文件获取
    async getSideBarType(str) {
      this.siderNum = str
      // if (str.name == "回收站") {
      //   this.$refs.uploadFiles.addFiles2 = true;
      // } else {
      //   this.$refs.uploadFiles.addFiles2 = false;
      // }
      if (str.name == '回收站') {
        this.addFiles2 = true
      } else {
        this.addFiles2 = false
      }
      // if (
      //   str.name == '网页采集文档' ||
      //   str.name == '云文档' ||
      //   str.name == '溯源报告' ||
      //   str.name == '授权'
      // ) {
      //   this.$refs.uploadFiles.uploadFiles = true
      //   this.addFiles = true
      // } else {
      //   this.$refs.uploadFiles.uploadFiles = false
      //   this.addFiles = false
      // }
      this.nubs = 1
      let params = {
        name: str.name,
        pageIndex: 1,
        pageSize: 10,
      }
      this.getsideBarType(params)
    },
    async getsideBarTypes(str) {
      this.conste.pageIndex = str.pageIndex
      let arrr = await sideBarType(this.conste)
      if (arrr.code == 200) {
        arrr.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
          // this.fileList.push(item)
        })
        this.fileList = arrr.data.records
        this.stry = arrr.data.records.length
        this.totals = arrr.data.total
      }
    },
    async getsideBarType(str) {
      this.conste = str
      this.type = 1
      this.siderNum = str
      let arrr = await sideBarType(str)
      if (arrr.code == 200) {
        this.parentId = arrr.data.current
        // if (str.name == "云文档" || str.name == "网页采集文档") {
        //   arrr = arrr.data;
        // }
        arrr.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
        })
        this.fileList = arrr.data.records
        this.totals = arrr.data.total
        this.stry = arrr.data.records.length
      }
    },
    // 头部按钮显示与隐藏
    handleSelection(arr) {
      if (this.nubs == 2 && arr != 1) {
        this.$refs.uploadFiles.uploadFiles = true
        this.addFiles = true
        this.$refs.uploadFiles.addFiles1 = false
      } else {
        if (arr == 1) {
          this.$refs.uploadFiles.addFiles1 = true
          this.$refs.uploadFiles.uploadFiles = false
          this.addFiles = false
        } else {
          this.$refs.uploadFiles.addFiles1 = false
          if (this.nubs1 == 2) {
            this.$refs.uploadFiles.uploadFiles = false
            this.addFiles = false
          } else {
            this.$refs.uploadFiles.uploadFiles = true
            this.addFiles = true
          }
        }
      }
    },
    // 文件获取
    async getDiskList(value) {
      let data = await getDiskList(value)
      data.data.records.forEach((item) => {
        item.showRightOp = false
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = '01'
            } else {
              item.nub = '03'
            }
            break
          case 1:
            item.nub = '04'
            break
          case 2:
            item.nub = '07'
            break
          case 3:
            item.nub = '08'
            break
          case 4:
            item.nub = '05'
            break
          case 5:
            item.nub = '06'
            break
          case 6:
            item.nub = '07'
            break
          case 7:
            item.nub = '04'
            break
          case 8:
            item.nub = '05'
            break
          case 9:
            item.nub = '14'
            break
          case 10:
            item.nub = '14'
            break
          case 11:
            item.nub = '09'
            break
          case 12:
            item.nub = '09'
            break
          case 13:
            item.nub = '09'
            break
          case 14:
            item.nub = '10'
            break
          case 15:
            item.nub = '01'
            break
          case 16:
            item.nub = '09'
            break
          case 17:
            item.nub = '12'
            break
          case 18:
            item.nub = '12'
            break
          case 19:
            item.nub = '13'
            break
          case 20:
            item.nub = '11'
            break
          case 21:
            item.nub = '15'
            break
          case 22:
            item.nub = '11'
            break
          case 23:
            item.nub = '15'
            break
        }
        // this.fileList.push(item)
      })
      this.fileList = data.data.records
      this.totals = data.data.total
      this.stry = data.data.records.length
    },
    // 解析数据
    analysisProgress() {
      this.timer = setInterval(() => {
        this.progress = this.progress.map((item) => {
          if (item > 80) {
            return item
          }
          const random = parseInt(Math.random() * 20)
          item = item + random
          return item
          // console.log(item);
        }, 500)
        const foo = this.progress.every((item) => {
          return item > 80
        })
        if (foo) {
          clearInterval(this.timer)
        }
      }, 300)
    },
    // 文件获取
    async getDiskLists(value) {
      this.type = 0
      if (this.nubs == 2) {
        this.$refs.uploadFiles.uploadFiles = true
        this.addFiles = true
      }
      this.siderNum = {
        id: 0,
        name: '',
      }
      this.parentId = Number(value.id)
      let data = await getDiskList(value)
      const list = []
      data.data.records.forEach((item) => {
        item.showRightOp = false
        item.progress = 0
        // 添加是否解析完毕字段
        if (this.isForEach) {
          this.diskDates.forEach((i) => {
            if (item.id == i.id) {
              item.analysis = i.isParse
              this.timer = setInterval(() => {
                if (item.progress > 80) {
                  return item.progress
                }
                const random = parseInt(Math.random() * 20)
                item.progress = item.progress + random

                // const foo = data.data.records.every((item) => {
                //   return item.progress > 80
                // })
                list.push(item.progress)
                if (item.progress > 80) {
                  if (item.analysis != 1) {
                    setTimeout(() => {
                      item.progress = 100
                      item.analysis = 0
                    }, 1000)
                  }
                  const foo = list.every((item) => {
                    return item.progress > 80
                  })
                  if (foo) {
                    clearInterval(this.timer)
                  }
                }
              }, 200)
            }
          })
        }
        // else {
        //   setTimeout(() => {
        //     item.progress = 100
        //     item.analysis = 0
        //   }, 1000)
        // }

        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = '01'
            } else {
              item.nub = '03'
            }
            break
          case 1:
            item.nub = '04'
            break
          case 2:
            item.nub = '07'
            break
          case 3:
            item.nub = '08'
            break
          case 4:
            item.nub = '05'
            break
          case 5:
            item.nub = '06'
            break
          case 6:
            item.nub = '07'
            break
          case 7:
            item.nub = '04'
            break
          case 8:
            item.nub = '05'
            break
          case 9:
            item.nub = '14'
            break
          case 10:
            item.nub = '14'
            break
          case 11:
            item.nub = '09'
            break
          case 12:
            item.nub = '09'
            break
          case 13:
            item.nub = '09'
            break
          case 14:
            item.nub = '10'
            break
          case 15:
            item.nub = '01'
            break
          case 16:
            item.nub = '09'
            break
          case 17:
            item.nub = '12'
            break
          case 18:
            item.nub = '12'
            break
          case 19:
            item.nub = '13'
            break
          case 20:
            item.nub = '11'
            break
          case 21:
            item.nub = '15'
            break
          case 22:
            item.nub = '11'
            break
          case 23:
            item.nub = '15'
            break
        }
      })
      this.fileList = data.data.records
      this.totals = data.data.total
      this.stry = data.data.records.length
    },

    //上传文件
    uploadFiles() {
      this.uploadVisible = true
    },
    // 关闭上传文件弹出框时触发
    handlerClose() {
      if (this.siderNum.id == 0) {
        this.isForEach = false
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 10,

          timeSort: 'desc',
        })
      } else {
        let params = {
          name: this.siderNum.name,
          pageIndex: 1,
          pageSize: 10,
        }
        this.getsideBarType(params)
      }
    },
    //新增文件夹
    addFile() {
      if (this.$store.state.sidebarActive == 10) {
        this.$message({
          type: 'error',
          message: '回收站不可新建文件夹!',
          duration: 1000,
        })
        return
      }
      this.addFilesPopup = true
    },
    // 下载
    async downloadFied(id, name, url) {
      // try {
      //   // eslint-disable-next-line
      //   DownloadDocument(name, this.$store.state.onlineBasePath + url);
      // } catch (e) {
      //   console.log(e);
      // }
      if (name.length > 49) {
        let arr = name.split('.')

        let names = name.slice(0, 49) + '.' + arr[arr.length - 1]
        window.open(
          this.$store.state.onlineBasePath + url + '?attname=' + names
        )
        return
      }
      window.open(this.$store.state.onlineBasePath + url + '?attname=' + name)
      // let data = await fileDownload(id);
      // let blob = new Blob([data], {
      //   type: "application/octet-stream",
      // });
      // // console.log(blob, "blob");
      // var link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // // console.log(link.href, "link.href======");
      // link.download = name;
      // link.click();
      // //释放内存
      // window.URL.revokeObjectURL(link.href);
    },
    // });

    /**
     * 处理开始下载文件
     */
    downloadFile(file_url, targetPath) {
      // Save variable to know progress
      var received_bytes = 0
      var total_bytes = 0
      // var req = request({
      //   method: "GET",
      //   uri: file_url,
      // });
      // var out = fs.createWriteStream(targetPath);
      // req.pipe(out);
      // req.on("response", function (data) {
      //   // Change the total bytes value to get progress later.
      //   console.log("data.headers['content-length']", data);
      //   total_bytes = parseInt(data.headers["content-length"]);
      // });
      // req.on("data", function (chunk) {
      //   // Update the received bytes
      //   received_bytes += chunk.length;
      //   showProgress(received_bytes, total_bytes);
      // });
      // req.on("end", function () {
      //   alert("File succesfully downloaded");
      // });
    },
    /**
     * 处理显示进度
     */
    showProgress(received, total) {
      var percentage = (received * 100) / total
    },

    // 新建文件夹确认
    handleSure() {
      if (this.addtitle == '新增文件夹') {
        this.addFilesSure()
      } else {
        let arr = this.form.name.split('.')
        console.log(arr)
        if (arr[0].length > 46) {
          this.$message.error('文件名不得超过50字，请重新编辑')
          return
        }
        this.hangleChangeName()
      }
    },
    // 新增文件夹确定
    async addFilesSure() {
      this.isForEach = false
      if (this.form.name == '') {
        this.$message({
          type: 'error',
          message: '文件夹名称不能为空',
          duration: 1000,
        })
        return
      }
      const data = {
        filetype: 0,
        parentid: this.parentId,
        filename: this.form.name,
      }

      const res = await addFiles(data)
      let arr = this.MyItem
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '新增成功！',
          duration: 1000,
          className: 'success',
        })
        this.addFilesPopup = false
        if (this.siderNum.id == 0) {
          this.getDiskLists({
            id: arr[arr.length - 1].id,
            pageIndex: 1,
            pageSize: 10,

            timeSort: 'desc',
          })
        } else {
          let params = {
            name: this.siderNum.name,
            pageIndex: 1,
            pageSize: 10,
          }
          this.getsideBarType(params)
        }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
          duration: 1000,
        })
      }
    },
    // 修改名称确定
    async hangleChangeName() {
      this.isForEach = false
      const data = {
        id: this.$refs.diskTable.rowItem.id,
        name: this.form.name,
      }
      const res = await changeFilesName(data)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功！',
          duration: 1000,
        })
        this.addFilesPopup = false
        if (this.siderNum.id == 0) {
          this.getDiskLists({
            id: this.MyItem[this.MyItem.length - 1].id,
            pageIndex: 1,
            pageSize: 10,
            sizeSort: '',
            timeSort: 'desc',
          })
        } else {
          let params = {
            name: this.siderNum.name,
            pageIndex: 1,
            pageSize: 10,
          }
          this.getsideBarType(params)
        }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
          duration: 1000,
        })
      }
    },
    // 新增文件夹弹窗 关闭回调
    closeAddpopup() {
      this.form.name = ''
      this.addtitle = '新增文件夹'
    },
    // 开通vip
    descBigBoxbtn() {
      this.$router.push({
        path: '',
      })
    },
    showType() {
      this.showDalget = !this.showDalget
      this.$refs.uploadFiles.addFiles1 = false
      if (this.nubs1 == 2) {
        this.$refs.uploadFiles.uploadFiles = false
        this.addFiles = false
      } else {
        this.$refs.uploadFiles.uploadFiles = true
        this.addFiles = true
      }
    },
    // 文件搜索
    async handleSearch(value) {
      if (value) {
        let data = await searchList({ name: value })
        if (data.code == 200) {
          if (data.data.records == null) {
            this.fileList = []
            this.totals = 0
            return
          }
          data.data.records.forEach((item) => {
            switch (item.filetype) {
              case 0:
                if (item.isDeleted == -1) {
                  item.nub = '01'
                } else {
                  item.nub = '03'
                }
                break
              case 1:
                item.nub = '04'
                break
              case 2:
                item.nub = '07'
                break
              case 3:
                item.nub = '08'
                break
              case 4:
                item.nub = '05'
                break
              case 5:
                item.nub = '06'
                break
              case 6:
                item.nub = '07'
                break
              case 7:
                item.nub = '04'
                break
              case 8:
                item.nub = '05'
                break
              case 9:
                item.nub = '14'
                break
              case 10:
                item.nub = '14'
                break
              case 11:
                item.nub = '09'
                break
              case 12:
                item.nub = '09'
                break
              case 13:
                item.nub = '09'
                break
              case 14:
                item.nub = '10'
                break
              case 15:
                item.nub = '01'
                break
              case 16:
                item.nub = '09'
                break
              case 17:
                item.nub = '12'
                break
              case 18:
                item.nub = '12'
                break
              case 19:
                item.nub = '13'
                break
              case 20:
                item.nub = '11'
                break
              case 21:
                item.nub = '15'
                break
              case 22:
                item.nub = '11'
                break
              case 23:
                item.nub = '15'
                break
            }
          })
          this.fileList = data.data.records
          this.totals = data.data.total
        }
      } else {
        this.getDiskList({
          id: 0,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: '',
          timeSort: 'desc',
        })
      }
    },
    // 移动
    moveTo() {
      this.$refs.diskTable.moveTo(this.checkData)
    },
    // 清空所有文件
    removeFile() {
      if (this.fileList.length == 0) {
        return
      }
      this.$refs.diskTable.content = '确定要清空所有文件吗?'
      this.$refs.diskTable.showRealNameTips = true
      this.$refs.diskTable.sttry = 0
    },
    // 刷新
    refresh() {
      this.isForEach = false
      if (this.siderNum.id == 0) {
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: '',
          timeSort: 'desc',
        })
      } else {
        let params = {
          name: this.siderNum.name,
          pageIndex: 1,
          pageSize: 10,
        }
        this.getsideBarType(params)
      }
    },
    // 删除文件
    async removeFiles() {
      this.isForEach = false
      await delFiles(this.checkData).then((res) => {
        if (res.code == 200) {
          this.showRealNameTips = false
          this.$refs.uploadFiles.addFiles1 = false
          this.$refs.uploadFiles.uploadFiles = true
          this.addFiles = true
          this.$message({
            type: 'success',
            message: '删除成功',
            duration: 1000,
          })
          if (this.siderNum.id == 0) {
            this.getDiskLists({
              id: this.MyItem[this.MyItem.length - 1].id,
              pageIndex: 1,
              pageSize: 10,
              sizeSort: '',
              timeSort: 'desc',
            })
          } else {
            let params = {
              name: this.siderNum.name,
              pageIndex: 1,
              pageSize: 10,
            }
            this.getsideBarType(params)
          }
        } else {
          this.showRealNameTips = false
          this.$message({
            type: 'error',
            message: res.message,
            duration: 1000,
          })
        }
      })
      // this.getMemoryFile()
    },
    // 删除文件
    deletsFiles() {
      this.showRealNameTips = true
    },
    // 回退
    Backoff() {
      this.isForEach = false
      if (this.MyItem.length != 1) {
        this.MyItem.pop()
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          sizeSort: '',
          timeSort: 'desc',
        })
      } else {
        this.$store.commit('ChangeSideBarId', 0)
        this.uploadFilesNav = true
        this.addFiles = true
        this.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: '',
          timeSort: 'desc',
        })
      }
    },
  },
}
</script>
<style>
.showDale {
  width: calc(100% - 40px);
  height: 20px;
  font-size: 12px;
  color: #696969;
  border-left: 1px solid #eceff1;
  border-right: 1px solid #eceff1;
  padding: 5px 10px;
  box-sizing: border-box;
  margin: 0 20px;
}
.el-message {
  min-width: 20px !important;
}
</style>

<style lang="scss" scoped>
.netDiskS {
  background-color: #fbf8fb;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0.375rem #ededed;
    /* border-radius: 10px; */
    background-color: #ededed;
  }
  .dialog-footer {
    width: 100%;
    text-align: right;
    display: block;
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000, $alpha: 0);
    z-index: 2000;
  }
  .box {
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    .left {
      position: relative;
      width: 100%;
      height: 100%;
      margin-right: 20px;
      display: flex;
      background-color: #fff;
    }
  }

  .disk-container {
    width: 100%;
    display: flex;
  }
  .office-nav {
    @include flex-between;
    min-width: 550px;
    max-width: calc(100% - 40px);
    margin: 15px 20px;
    .main {
      @include flex-start;
      .upload-btn,
      .create-dir {
        @include flex-center;
        padding: 0 11px;
        font-size: 12px;
        height: 25px;
        font-weight: 500;
        line-height: 25px;
        border-radius: 12px;
        cursor: pointer;
      }
      .upload-btn {
        margin-left: 20px;
        background: #ff6900;
        color: $color-text-white;
        .upload-btn-icon {
          margin-right: 5px;
          display: inline-block;
          width: 9px;
          height: 10px;
          @include backgroundGroup('~assets/images/disk/upload-btn-icon.png');
        }
      }
      .create-dir {
        color: $color-text-active;
        margin-left: 20px;
        background-color: #fffaf3;
        .create-dir-icon {
          margin-right: 5px;
          display: inline-block;
          width: 10px;
          height: 11px;
          @include backgroundGroup('~assets/images/disk/create-dir-icon.png');
        }
      }
      .intelli-analysis {
        @include flex-start;
        margin-left: 20px;
        cursor: pointer;
        span {
          margin-left: 5px;
          font-size: 12px;
          color: #999;
        }
        ::v-deep .el-switch__core {
          width: 30px !important;
          height: 15px;
        }
        ::v-deep .el-switch__core:after {
          width: 11px;
          height: 11px;
        }
        /deep/ .el-switch.is-checked .el-switch__core::after {
          margin-left: -13px !important;
        }
      }
      .storage-detail {
        @include flex-start;
        margin-left: 20px;
        font-size: $font-size-small;
        .storage-proportion {
          min-width: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #a5a8b1;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
    }

    .create-dir1 {
      font-size: 12px;
      color: #4587ff;
      cursor: pointer;
      margin-right: 20px;
    }
    .analysis-progress {
      @include flex-start;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      .el-progress {
        width: 150px;
        margin-left: 5px;
      }
    }
  }
  .dik-nav {
    @include flex-between;
    height: 40px;
    margin: 0 20px;
    border: 1px solid #eef0f4;
    .disk-operate {
      @include flex-between;
      width: 70px;
      padding: 0 10px;
      height: 29px;
      border-right: 1px solid #eceff1;
      box-sizing: border-box;
      .disk-operate-prev {
        cursor: pointer;
        display: inline-block;
        width: 11px;
        height: 18px;
        margin-top: 18%;
        @include backgroundGroup('~assets/images/disk/disk-operate-prev.png');
        background-size: 7px 10px;
        &:active {
          transform: scale(1.3);
        }
      }
      .disk-operate-refresh {
        cursor: pointer;
        display: inline-block;
        width: 14px;
        height: 11px;
        @include backgroundGroup(
          '~assets/images/disk/disk-operate-refresh.png'
        );
        transition: all 0.1s ease-out;
        &:active {
          transform: rotate(20deg);
        }
      }
    }
    .disk-path {
      @include flex-start;
      padding: 0 12px;
      flex: 1;
      height: 40px;
    }
    .show-type {
      cursor: pointer;
      margin-right: 20px;
      display: inline-block;
      width: 12px;
      height: 13px;
      @include backgroundGroup('~assets/images/disk/show-type.png');
    }
  }
  @media screen and (min-width: 1220px) {
    .dik-nav {
      @include flex-between;
      height: 46px;

      border-bottom: 1px solid #eef0f4;
      .disk-operate {
        @include flex-between;
        width: 108px;
        padding: 0 18px;
        height: 40px;
        border-right: 1px solid #eceff1;
        box-sizing: border-box;
        .disk-operate-prev {
          cursor: pointer;
          display: inline-block;
          width: 14px;
          height: 24px;
          margin-top: 16%;
          @include backgroundGroup('~assets/images/disk/disk-operate-prev.png');
          background-size: 7px 12px;
          &:active {
            transform: scale(1.3);
          }
        }
        .disk-operate-refresh {
          cursor: pointer;
          display: inline-block;
          width: 19px;
          height: 14px;
          @include backgroundGroup(
            '~assets/images/disk/disk-operate-refresh.png'
          );
          transition: all 0.1s ease-out;
          &:active {
            transform: rotate(20deg);
          }
        }
      }
      .disk-path {
        @include flex-start;
        padding: 0 20px;
        flex: 1;
        height: 40px;
        font-size: 14px;
      }
      .show-type {
        cursor: pointer;
        margin-right: 20px;
        display: inline-block;
        width: 15px;
        height: 16px;
        @include backgroundGroup('~assets/images/disk/show-type.png');
      }
    }
  }
  .disk-content {
    // width: calc(100% - 150px);
    background-color: #fff;
    flex: 1;
    height: calc(100vh - 60px);
  }
  .interest {
    ::v-deep .el-dialog {
      width: 360px;
      height: 318px;
      background: #ffffff;
      border: 1px solid #e8eaef;
      box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
      border-radius: 8px;
      .el-dialog__close {
        font-size: 16px;
      }
      .el-dialog__body {
        padding: 17px 27px;
        .content {
          .content-item {
            margin-top: 28px;
            .content-item-title {
              display: flex;
              align-items: center;
              font-size: $font-size-small;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              .content-item-line {
                width: 3px;
                height: 12px;
                background: #f5d693;
                box-shadow: 2px 0px 0px 0px #fff6d3;
                margin-right: 6px;
              }
            }
            .content-desc {
              margin-top: 14px;
              display: flex;
              .desc-item {
                width: 74px;
                height: 84px;
                background: #ffffff;
                box-shadow: 0px 4px 16px 0px rgba(188, 188, 188, 0.3);
                border-radius: 4px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .desc-item-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .desc-item-content-icon {
                    width: 19px;
                    height: 15px;
                  }
                  .drillIcon {
                    @include backgroundGroup('~assets/images/drillIcon.png');
                  }
                  .textIcon {
                    @include backgroundGroup('~assets/images/textIcon.png');
                  }
                  .desc-item-content-title {
                    margin-top: 11px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #222222;
                  }
                  .desc-item-content-story {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 10px;
                  }
                }
              }
              .desc-bigBox {
                // width: 154px;
                // height: 122px;
                margin-left: 6px;
                text-align: center;
                .desc-bigBox-title {
                  margin-top: 14px;
                  font-size: $font-size-medium;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #222222;
                  span {
                    color: #ea2020;
                  }
                }
                .desc-bigBox-story {
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  margin-top: 3px;
                }
                .desc-bigBox-btn {
                  cursor: pointer;
                  width: 105px;
                  height: 27px;
                  border-image: linear-gradient(0deg, #f9d7a8, #feedce) 10 10;
                  background: linear-gradient(90deg, #f9d7a9 0%, #fdeac9 100%);
                  border-radius: 13px;
                  line-height: 27px;
                  margin-top: 10px;
                  font-size: $font-size-small;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #2d2e31;
                }
              }
            }
          }
          .content-item:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1220px) {
    .disk-content {
      width: 100%;
      background-color: #fff;
      // flex: 1;
    }
    .interest {
      ::v-deep .el-dialog {
        width: 513px;
        height: 446px;
        background: #ffffff;
        border: 1px solid #e8eaef;
        box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
        border-radius: 12px;
        .el-dialog__close {
          font-size: 16px;
        }
        .el-dialog__body {
          padding: 11px 39px 41px 41px;
          .content {
            .content-item {
              margin-top: 28px;
              .content-item-title {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
                .content-item-line {
                  width: 7px;
                  height: 18px;
                  background: #f5d693;
                  box-shadow: 2px 0px 0px 0px #fff6d3;
                  margin-right: 8px;
                }
              }
              .content-desc {
                margin-top: 19px;
                display: flex;
                .desc-item {
                  width: 107px;
                  height: 122px;
                  background: #ffffff;
                  box-shadow: 0px 4px 16px 0px rgba(188, 188, 188, 0.3);
                  border-radius: 6px;
                  margin-right: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .desc-item-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .desc-item-content-icon {
                      width: 28px;
                      height: 26px;
                    }
                    .drillIcon {
                      @include backgroundGroup('~assets/images/drillIcon.png');
                    }
                    .textIcon {
                      @include backgroundGroup('~assets/images/textIcon.png');
                    }
                    .desc-item-content-title {
                      margin-top: 12px;
                      font-size: 18px;
                      font-family: PingFang SC;
                      font-weight: bold;
                      color: #222222;
                    }
                    .desc-item-content-story {
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                      margin-top: 10px;
                    }
                  }
                }
                .desc-bigBox {
                  // width: 154px;
                  // height: 122px;
                  margin-left: 6px;
                  text-align: center;
                  .desc-bigBox-title {
                    margin-top: 20px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #222222;
                    span {
                      color: #ea2020;
                    }
                  }
                  .desc-bigBox-story {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 5px;
                  }
                  .desc-bigBox-btn {
                    cursor: pointer;
                    width: 154px;
                    height: 39px;
                    border-image: linear-gradient(0deg, #f9d7a8, #feedce) 10 10;
                    background: linear-gradient(
                      90deg,
                      #f9d7a9 0%,
                      #fdeac9 100%
                    );
                    border-radius: 20px;
                    line-height: 39px;
                    margin-top: 15px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #2d2e31;
                  }
                }
              }
            }
            .content-item:first-of-type {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
  .addFolder {
    ::v-deep .el-dialog {
      .el-form-item__label {
        text-align: center;
      }
      .el-input {
        width: 90%;
      }
      .el-form-item {
        margin-bottom: 12px;
      }
      .el-dialog__title {
        line-height: 20px;
        font-size: 14px;
      }
      .el-dialog__body {
        padding: 20px 12px;
      }
      .el-form-item__label {
        font-size: 12px;
      }
      .el-input__inner {
        font-size: 12px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
      }
      .el-dialog__footer {
        padding: 6px 20px 14px;
      }
      .el-button {
        padding: 8px 15px;
        font-size: 12px;
        border-radius: 3px;
      }
    }
  }
  // 文件上传
  .upload {
    ::v-deep .el-dialog {
      width: 75%;
      min-height: 182px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 36px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 14px;
        }
      }
      .el-dialog__body {
        padding: 0px 22px 22px;
        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }
  .real-name-dialog {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 20vh !important;
      width: 340px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 10px 10px;
        .el-dialog__title {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 11px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 20px 61px 35px 59px;
        .content {
          margin-top: 8px;
          @include flex-center;
          align-items: normal;
          flex: 1;
          font-size: 16px;
          color: #333333;
        }
        .footer {
          @include flex-between;
          @include noselect;
          > span {
            padding: 0 8px;
            font-size: 14px;
            font-weight: 500;
            color: #4587ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.dialog-footer {
  width: 100%;
  text-align: right;
  display: block;
}
</style>
<style>
.showDale {
  width: calc(100% - 40px);
  height: 20px;
  font-size: 12px;
  color: #696969;
  border-left: 1px solid #eceff1;
  border-right: 1px solid #eceff1;
  padding: 5px 10px;
  box-sizing: border-box;
  margin: 0 20px;
}
.el-message {
  min-width: 20px !important;
}
</style>
